import React, { Fragment, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { slideIn } from "../../utils/motion";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, contact } from "../../actions/contactAction";
import { useAlert } from "react-alert";
import { NEW_CONTACT_RESET } from "../../constants/contactConstants";
import { SectionWrapper, MetaData } from "../../hoc";

const NewContact = () => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const { loading, error, success } = useSelector((state) => state.contact);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactn, setContactn] = useState("");
  const [weburl, setWeburl] = useState("");
  const [message, setMessage] = useState([]);

  const handleContactnChange = (e) => {
    const input = e.target.value;
    const numbersOnly = input.replace(/[^0-9]/g, "");
    setContactn(numbersOnly);
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("Send Successfully");

      dispatch({ type: NEW_CONTACT_RESET });
    }
  }, [dispatch, alert, error, success]);

  const createProductSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("email", email);
    myForm.set("contact", contactn);
    myForm.set("weburl", weburl);
    myForm.set("message", message);

    dispatch(contact(myForm));
  };

  return (
    <Fragment>
      <MetaData title="ASR Techno" />
      <motion.section
        initial={{ y: "-100%" }}
        animate={{ y: "0%" }}
        transition={{ duration: 0.5 }}
        exit={{ y: "100" }}
        className=" min-h-[100vh] pt-14 w-full relative top-0 left-0 bg-[#f9eff1]"
        key="about"
        id="contact"
      >
        <div className={`   overflow-hidden py-20  `}>
          <motion.div variants={slideIn("left", "tween", 0.2, 1)}>
            <p className="text-[#073230]  md:text-8xl sm:text-[50px]  tracking-wider text-[45px] text-center ">
              Have a <span className="text-[#5751bb]">project?</span>
            </p>
            <h3 className="text-[#073230]  md:text-8xl sm:text-[50px]   text-[45px] text-center  ">
              Let's make something{" "}
              <span className="text-[#5751bb]">great!</span>
            </h3>
          </motion.div>
          <div
            className={`xl:pt-12 flex  flex-col-reverse  px-5 lg:pl-28 overflow-hidden  `}
          >
            <motion.div
              variants={slideIn("left", "tween", 0.2, 1)}
              className="flex-[0.75] bg-black-100 px-4 rounded-2xl "
            >
              <form
                encType="multipart/form-data"
                onSubmit={createProductSubmitHandler}
                className="pt-12 flex flex-col gap-8"
              >
                <div className="flex flex-col md:flex-row w-full gap-4 items-center justify-center ">
                  <label className="flex flex-col w-full  lg:w-[35%]">
                    <span className="text-[#073230]  content ">Your Name</span>
                    <input
                      type="text"
                      name="name"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="What's your good name?"
                      className="bg-transparent border-b-2 border-[#5751bb] py-4 px-2 placeholder:text-secondary text-[#073230]  outline-none  content "
                    />
                  </label>
                  <label className="flex flex-col w-full  lg:w-[35%]">
                    <span className="text-[#073230]  content ">Your email</span>
                    <input
                      type="email"
                      name="email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="What's your email address?"
                      className="bg-transparent border-b-2 border-[#5751bb] py-4 px-2 placeholder:text-secondary text-[#073230]  outline-none  content "
                    />
                  </label>
                </div>

                <div className="flex flex-col md:flex-row w-full gap-4 items-center justify-center">
                  <label className="flex flex-col w-full lg:w-[35%]">
                    <span className="text-[#073230] content">
                      Your Contact Number
                    </span>
                    <input
                      value={contactn}
                      onChange={handleContactnChange}
                      type="text"
                      name="phone"
                      placeholder="What's your Contact Number?"
                      className="bg-transparent border-b-2 border-[#5751bb] py-4 px-2 placeholder:text-secondary text-[#073230] outline-none content"
                    />
                  </label>

                  <label className="flex flex-col w-full lg:w-[35%]">
                    <span className="text-[#073230]  content ">Url</span>
                    <input
                      value={weburl}
                      onChange={(e) => setWeburl(e.target.value)}
                      type="text"
                      name="weburl"
                      placeholder="Enter Your Website URL?"
                      className="bg-transparent border-b-2 border-[#5751bb] py-4 px-2 placeholder:text-secondary text-[#073230]  outline-none  content "
                    />
                  </label>
                </div>

                <div className="flex flex-col md:flex-row w-full gap-4 items-center justify-center">
                  <label className="flex flex-col w-full lg:w-[71%]">
                    <span className="text-[#073230]   content">
                      Your Message
                    </span>
                    <textarea
                      rows={1}
                      name="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="What you want to say?"
                      className="bg-transparent py-4 px-2 placeholder:text-secondary text-[#073230] pt-[6px] outline-none border-b-2 border-[#5751bb] content "
                    />
                  </label>
                </div>
                <button
                  type="submit"
                  className="bg-[#cecece]  mx-auto  py-2 px-8 rounded-[50px] outline-none w-fit text-[#073230] hover:bg-white hover:text-blue-950 border border-[#5751bb] shadow-2xl"
                >
                  {loading ? "Sending..." : "Send"}
                </button>
              </form>
            </motion.div>

            <motion.div
              variants={slideIn("right", "tween", 0.2, 1)}
              className="pt-8 text-start mx-auto md:text-justify px-5 "
            >
              <div>
                <h2 className="content text-xl text-[#073230] ">
                  {" "}
                  Address : Malviya Nagar, Jaipur, Rajasthan (302017)
                </h2>
                <h2 className="content text-xl text-[#073230]">
                  {" "}
                  Let's Talk : 7357094260{" "}
                </h2>
                <h2 className="hover:text-white text-[#073230] content text-xl">
                  {" "}
                  Email Id :{" "}
                  <a
                    href="mailto:info@asrtechno.in"
                    className="content text-xl"
                  >
                    info@asrtechno.in
                  </a>{" "}
                </h2>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.section>
    </Fragment>
  );
};

export default SectionWrapper(NewContact, "contact");
