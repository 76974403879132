import {
  ADMIN_TESTIMONIAL_REQUEST,
  ADMIN_TESTIMONIAL_SUCCESS,
  ADMIN_TESTIMONIAL_FAIL,
  CLEAR_ERRORS,
} from "../constants/contactConstants";

import axios from "axios";

// Get All Testimonial For Admin
export const getAdminTestimonial = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_TESTIMONIAL_REQUEST });

    const { data } = await axios.get("/api/v1/testimonials");

    dispatch({
      type: ADMIN_TESTIMONIAL_SUCCESS,
      payload: data.testimonials,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_TESTIMONIAL_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
