import React, { useEffect } from "react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { SectionWrapper, MetaData } from "../../hoc";
import { slideIn } from "../../utils/motion";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";

import {
  getAdminTestimonial,
  clearErrors,
} from "../../actions/testimonialAction";

const Testmonials = () => {
  const dispatch = useDispatch();

  const { error, testimonials } = useSelector(
    (state) => state.admintestimonial
  );
  SwiperCore.use([Navigation, Pagination, Autoplay]);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    dispatch(getAdminTestimonial());
  }, [dispatch, error]);

  return (
    <section id="testMonials">
      <MetaData title="ASR Techno" />
      <div className="pb-20 bg-[#cecece] text-[#915151]  ">
        <motion.p
          variants={slideIn("left", "", 0.1, 1)}
          className="text-center text-[45px] sm:text-6xl md:text-8xl py-5 pb-10 tracking-wider "
        >
          Testimonials
        </motion.p>
        <Swiper spaceBetween={30} slidesPerView={1} autoplay={{ delay: 2000 }}>
          {testimonials &&
            testimonials.map((testimonial) => (
              <SwiperSlide key={testimonial._id}>
                <div className="flex flex-col items-center justify-center w-auto mx-5 gap-2 md:w-[70%]  2xl:w-[50%] md:mx-auto ">
                  <p className="text-center text-[10px] md:text-lg content">
                    " {testimonial.message} "
                  </p>
                  <p className="text-center">{testimonial.name}</p>
                  <p className="text-center">{testimonial.designation}</p>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </section>
  );
};

export default SectionWrapper(Testmonials, "testMonials");
