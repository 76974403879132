import React from "react";
import { SectionWrapper, MetaData } from "../../hoc";
import { fadeIn, textVariant } from "../../utils/motion";
import { motion, AnimatePresence } from "framer-motion";
import RowS from "./RowS";
import RRowS from "./RRowS";

const mpb1 = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686819090/media_plan1_s4elsw.png",
    des: "  We understand that effective media planning and buying is a crucial component of any successful digital marketing campaign. Our dedicated team of experts leverages their in-depth industry knowledge and cutting-edge tools to develop comprehensive media plans tailored to your specific goals and target audience. With our meticulous planning process, we analyze market trends, consumer behavior, and competitor strategies to identify the most opportune advertising channels and platforms. From search engine advertising and social media campaigns to display ads and programmatic  advertising, we meticulously select the channels that will yield the highest returns for your investment.",
  },
];
const mpb2 = [
  {
    id: 1,
    img1: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686819090/media_plan2_lo5xcw.png",
    des1: "Moreover, our media buying strategies are designed to maximize your budget while optimizing campaign performance. We negotiate favorable rates, secure premium ad placements, and implement advanced targeting techniques to ensure your ads reach the right audience at the right time. Our focus is not just on generating impressions but driving meaningful engagement and conversions that positively impact your bottom line. Take your digital marketing to new heights with ASR Techno's exceptional media planning and buying services. Let us be your trusted partner in achieving remarkable online visibility, brand awareness, and business growth.",
  },
];

const MBP = () => {
  return (
    <>
      <AnimatePresence>
        <motion.section
          initial={{ x: "-100%" }}
          animate={{ x: "0%" }}
          transition={{ duration: 1 }}
          exit={{ x: "100" }}
          className=" min-h-[100vh] pt-14 w-full relative top-0 left-0 bg-[#ee7b42]"
          key="mediaplanning"
          id="mediaplanning"
        >
          <MetaData title=" Media Planning & Buying -- ASR Techno" />

          <div className="px-5 pt-5 lg:px-28  pb-[70px] drop-shadow-sm">
            <motion.div variants={textVariant()}>
              <h2 className="text-white  md:text-8xl text-center  sm:text-[40px]  text-[30px] tracking-wider font-thin">
                Media Planning & Buying
              </h2>
            </motion.div>

            <motion.p
              variants={fadeIn("", "", 0.5, 1)}
              className="my-10   text-[18px] text-center   content  "
            >
              Are you looking to maximize your brand's reach and impact in the
              digital realm? Look no further than ASR Techno, We offer
              exceptional media planning and buying services. With our expertise
              and strategic approach, we help businesses navigate the complex
              landscape of online advertising to achieve optimal results.
            </motion.p>

            {mpb1 && mpb1.map((rows1) => <RowS key={rows1.id} rows1={rows1} />)}
            {mpb2 &&
              mpb2.map((rrows1) => <RRowS key={rrows1.id} rrows1={rrows1} />)}
          </div>
        </motion.section>
      </AnimatePresence>
    </>
  );
};

export default SectionWrapper(MBP, "mediaplanning");
