import React from 'react';

const Header = () => {
  let Links = [
    { name: 'Home', link: '/' },
    { name: 'Service', link: '#service' },
    { name: 'About', link: '#about' },
    { name: 'Our Story', link: '#our-story' },
    { name: 'Our Work', link: '#our-work' },
    { name: 'Contacts', link: '#contact' },
    { name: 'Old Us', link: 'https://asrold.onrender.com' },
  ];


  return (
    <div className=" w-full  fixed md:bottom-0 bottom-[50px] left-[70%] md:left-0 z-50  md:pb-20">

        <ul
        >
          {Links.map((link) => (
            <li key={link.name} className="md:ml-8 text-[16px] tracking-wider ">
              <a href={link.link} className="text-blue-950  bg-transparent hover:p-1 hover:px-4 hover:rounded-full hover:text-green-950 duration-500" >
                {link.name}
              </a>
            </li>
          ))}
        
        </ul>
     
    </div>
  );
};

export default Header;
