import React from "react";
import { SectionWrapper, MetaData } from "../../hoc";
import { fadeIn, textVariant } from "../../utils/motion";
import { motion, AnimatePresence } from "framer-motion";
import RowS from "./RowS";
import RRowS from "./RRowS";

const ims1 = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686819093/integrated1_nijuxn.png",
    des: " Experience the power of integrated marketing solutions with ASR Techno, Our company dedicated to elevating your brand's online presence and driving impactful results. Our comprehensive suite of services is designed to seamlessly integrate multiple marketing channels and tactics, providing you with a cohesive and strategic approach to maximize your reach, engage your target audience, and achieve your business objectives. We understand that the digital landscape is ever-evolving, and staying ahead of the curve is crucial for your brand's success. That's why our team of seasoned experts harnesses the latest industry trends, cutting-edge technologies, and data-driven insights to craft tailored marketing strategies that resonate with your audience and outshine your competitors.",
  },
];

const ims2 = [
  {
    id: 1,
    img1: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686819095/integrated2_jh5fnl.png",
    des1: "  Our integrated marketing solutions encompass a wide range of services, including search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, content marketing, email marketing, conversion rate optimization (CRO), and more. By combining these channels, we create a holistic and cohesive marketing ecosystem that amplifies your brand message across various platforms, ensuring consistent brand visibility and increased customer engagement. We understand that the digital landscape is ever-evolving, and staying ahead of the curve is crucial for your brand's success. That's why our team of seasoned experts harnesses the latest industry trends, cutting-edge technologies, and data-driven insights to craft tailored marketing strategies that resonate with your audience and outshine your competitors.",
  },
];

const ims3 = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686819090/integrated3_tjtafc.png",
    des: "With ASR Techno's integrated marketing solutions, you can streamline your marketing efforts, optimize your budget, and achieve higher ROI. Our team takes a collaborative approach, working closely with you to understand your unique goals, target audience, and industry landscape. Moreover, our focus on data analytics and performance tracking ensures that your marketing campaigns are continuously optimized and fine-tuned for maximum effectiveness. We provide comprehensive reporting and insights, allowing you to make informed decisions, measure your success, and adapt your strategies accordingly. Elevate your brand's online presence and unlock new opportunities with ASR Techno's integrated marketing solutions.",
  },
];
const IMS = () => {
  return (
    <>
      <AnimatePresence>
        <motion.section
          initial={{ x: "-100%" }}
          animate={{ x: "0%" }}
          transition={{ duration: 1 }}
          exit={{ x: "100" }}
          className=" min-h-[100vh] pt-10 w-full relative top-0 left-0 bg-[#ee7b42]"
          key="marketing"
          id="marketing"
        >
          <MetaData title="Integrated Marketing Solutions -- ASR Techno" />
          <div className="px-5  lg:px-28  pb-[70px] drop-shadow-sm">
            <motion.div variants={textVariant()}>
              <h2 className="text-white  md:text-8xl text-center mb-10 sm:text-[40px]  text-[30px] tracking-wider font-thin">
                Integrated Marketing Solutions
              </h2>
            </motion.div>

            {ims1 && ims1.map((rows1) => <RowS key={rows1.id} rows1={rows1} />)}
            {ims2 &&
              ims2.map((rrows1) => <RRowS key={rrows1.id} rrows1={rrows1} />)}
            {ims3 && ims3.map((rows1) => <RowS key={rows1.id} rows1={rows1} />)}
          </div>
        </motion.section>
      </AnimatePresence>
    </>
  );
};

export default SectionWrapper(IMS, "marketing");
