import React, { useEffect } from "react";
import { SectionWrapper, MetaData } from "../../hoc";
import "./TextVideo.css";
import ASR from "../../Images/asr.png";

const TextVideo = () => {
  return (
    <section id="our-story">
      <MetaData title="ASR Techno" />
      

      <div className="textvideo homechange">
        <div id="fly-in">
          <div>
            <span className="mt-[10vh] md:mt-0">We are</span>ASR Techno
          </div>
          <div className="seconddiv">
            A 360-degree<span>Digital Marketing Solution</span>
            <span>Agency</span>
          </div>
          <div>
            <span></span>
            From India
          </div>
          <div>
            <span>Don't get</span> Kinky yet.
          </div>
          <div>
            <span>Our digital</span>Marketing
          </div>
          <div>
            Our Recipe<span>is unique</span>
          </div>
          <div>
            <span>to </span>
            Every Customer.
          </div>
          <div>
            <span>we define</span> Strategies
          </div>
          <div>
            <span>develop</span> Customer centric
          </div>
          <div>
            <span></span>
            Campaigns
          </div>
          <div>
            <span>and</span> Experiences
          </div>
          <div>
            <span></span>
            of course....
          </div>
          <div>
            <span></span>
            WE ARE
          </div>
          <div>
            <span></span>
            ASR Techno
          </div>
          <div>
            <span>not</span> Rocket Scientists.
          </div>
          <div>
            <span>We</span> Launch <span>only</span>
          </div>
          <div>
            <span></span>
            Products & Services
          </div>
          <div>
            <span></span>
            We Are
          </div>
          <div>
            100 X <span>Cheaper....</span>
          </div>
          <div>
            <span>than your</span>Marketing Mistakes.
          </div>
          <div>
            Horrible <span></span>Dancer
          </div>
          <div>
            <span>yet we make</span>Product Dance
          </div>
          <div>
            <span>to our</span>Tunes
          </div>
          <div>
            <span>we</span>Pop Up
          </div>
          <div>
            <span>your</span>Brands presence
          </div>
          <div>
            <span>So are you</span>Ready
          </div>
          <div>
            <span>to</span>Dream Big
          </div>

          <div className="vimg">
            <img
              className="mx-auto w-[95%] md:w-auto"
              src={ASR}
              alt="ASR Techno"
            />
            <span>We Create, You Succeed</span>
          </div>
        </div>
      </div>

    </section>
  );
};

export default SectionWrapper(TextVideo, "our-story");
