import React, { Fragment } from "react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { SectionWrapper, MetaData } from "../../hoc";
import { textVariant } from "../../utils/motion";
import { motion } from "framer-motion";

const clogo = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687329614/zaralogo_ygkzvi.png",
  },
  {
    id: 2,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687329612/mangologo_msxudn.png",
  },
  {
    id: 3,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687329612/iplogo_f8ezyb.png",
  },
  {
    id: 4,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687329613/salogo_gc0huf.png",
  },
  {
    id: 5,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687329614/slogo_mvgma5.png",
  },
  {
    id: 6,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687329615/sblogo_ag7rbo.png",
  },
  {
    id: 7,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687330398/klogo_nh1blw.png",
  },
  {
    id: 8,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687330401/kslogo_eriwd3.png",
  },
  {
    id: 9,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687330532/kalogo_q8guam.png",
  },
  {
    id: 10,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687329612/neslogo_uynqjh.png",
  },
  {
    id: 11,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687329612/nush_jipgi8.png",
  },
];

const Detail = () => {
  SwiperCore.use([Autoplay]);

  return (
    <Fragment>
      <MetaData title="ASR Techno" />
      <motion.section
        initial={{ y: "-100%" }}
        animate={{ y: "0%" }}
        transition={{ duration: 0.5 }}
        exit={{ y: "100" }}
        className=" md:min-h-[27vh] lg:min-h-[45vh]  w-full relative top-0 left-0 bg-[#f9eff1]"
        key="client"
        id="client"
      >
        <div className="h-[50%]  pb-[5%] px-[5%]">
          <motion.h2
            variants={textVariant()}
            className="text-[#1c2042]  text-center sm:text-6xl text-[45px]  tracking-wider md:text-8xl mb-[4%]  font-thin"
          >
            {" "}
            Our Clients
          </motion.h2>

          <Swiper
            spaceBetween={1}
            // loop={true}
            autoplay={{ delay: 1000 }}
            grabCursor={true}
            breakpoints={{
              350: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 10,
              },
            }}
          >
            <div className=" flex flex-row w-[100%] items-center justify-center py-5 pb-10 gap-2  ">
              {clogo &&
                clogo.map((logo) => (
                  <SwiperSlide key={logo.id}>
                    <div className="    flex    justify-center   cursor-pointer">
                      <img src={logo.img} alt="logo" className="w-[100%]  " />
                    </div>
                  </SwiperSlide>
                ))}
            </div>
          </Swiper>
        </div>
      </motion.section>
    </Fragment>
  );
};

export default SectionWrapper(Detail, "client");
