import {
    CONTACT_USER_REQUEST,
    CONTACT_USER_SUCCESS,
    CONTACT_USER_FAIL,
    NEW_CONTACT_RESET,
    CLEAR_ERRORS,
  } from "../constants/contactConstants";

  export const contactReducer = (state = { contact: {} }, action) => {
    switch (action.type) {
      case CONTACT_USER_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case CONTACT_USER_SUCCESS:
        return {
          loading: false,
          success: action.payload.success,
          contact: action.payload.contact,
        };
      case CONTACT_USER_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case NEW_CONTACT_RESET:
        return {
          ...state,
          success: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };