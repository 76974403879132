import React from "react";
import { SectionWrapper, MetaData } from "../../hoc";
import { textVariant } from "../../utils/motion";
import { motion, AnimatePresence } from "framer-motion";

import RowS from "./RowS";
import RRowS from "./RRowS";

const vr1 = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686820245/vr1_x8wpb8.png",
    des: "   ASR Techno groundbreaking virtual reality (VR) services, revolutionizing the digital landscape and taking your brand to extraordinary heights. As a leading digital marketing company, we understand the power of immersive experiences in capturing and retaining audience attention. With our VR expertise, we create transformative and engaging virtual environments that transport your customers to extraordinary domain We ensure your VR experiences reach a wider audience through our strategic digital marketing tactics. We optimize your VR content for search engines, incorporating relevant keywords and metadata, enhancing its discoverability and driving organic traffic.",
  },
];
const vr2 = [
  {
    id: 1,
    img1: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686820247/vr2_itwpjm.png",
    des1: " Additionally, we integrate social media and influencer marketing strategies to amplify your VR campaigns, generating buzz and fostering engagement across various platforms. With ASR Techno as your trusted partner, you can unlock the immense potential of virtual reality to elevate your brand presence, engage customers on a profound level, and ultimately drive conversions and business growth. Embrace the future of digital marketing with our comprehensive VR services and embark on a journey of innovation and success.",
  },
];

const VR = () => {
  return (
    <>
      <AnimatePresence>
        <motion.section
          initial={{ x: "-100%" }}
          animate={{ x: "0%" }}
          transition={{ duration: 1 }}
          exit={{ x: "100" }}
          className=" min-h-[100vh] pt-10 w-full relative top-0 left-0 bg-[#ee7b42]"
          key="virtual"
          id="virtual"
        >
          <MetaData title="Virtual Reality -- ASR Techno" />

          <div className="px-5  lg:px-28  pb-[70px] drop-shadow-sm">
            <motion.div variants={textVariant()}>
              <h2 className="text-white  md:text-8xl text-center mb-10 sm:text-[40px]   text-[30px] tracking-wider font-thin">
                Virtual Reality
              </h2>
            </motion.div>

            {vr1 && vr1.map((rows1) => <RowS key={rows1.id} rows1={rows1} />)}
            {vr2 &&
              vr2.map((rrows1) => <RRowS key={rrows1.id} rrows1={rrows1} />)}
          </div>
        </motion.section>
      </AnimatePresence>
    </>
  );
};

export default SectionWrapper(VR, "virtual");
