import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { contactReducer } from "./reducers/contactReducer";
import { testimonialsReducer } from "./reducers/testimonialReducer";

const reducer = combineReducers({
  contact: contactReducer,
  admintestimonial: testimonialsReducer,
});

let initialState = {};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,

  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
