import React from "react";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { SectionWrapper, MetaData } from "../../hoc";
import { motion } from "framer-motion";
import { textVariant } from "../../utils/motion";
const plogo = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687334136/istart_olz4py.png",
  },
  {
    id: 2,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687334134/google_gvxuvb.png",
  },
  {
    id: 3,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687334135/canva_yauoxu.png",
  },
  {
    id: 4,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687334140/wordpress_jmmhqz.png",
  },
  {
    id: 5,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687334135/salesforce_tl8jqb.png",
  },
  {
    id: 6,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687334136/meta_lt0ixs.png",
  },
  {
    id: 7,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687334138/zoho_zggi2i.png",
  },
  {
    id: 8,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687334142/semrush_tsawgn.png",
  },
  {
    id: 9,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687334137/startupindia_nqg2be.png",
  },
  {
    id: 10,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687334137/whatsapp_ptv1pi.png",
  },
  {
    id: 11,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687334135/godaddy_puross.png",
  },
  {
    id: 12,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687334134/aws_ha7ml8.png",
  },
  {
    id: 13,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687334134/adobe_in0ezf.png",
  },
  {
    id: 14,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687334136/linkedin_vi48vr.png",
  },
  {
    id: 15,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1687334137/shopify_vjgmpa.png",
  },
];

const Partner = () => {
  SwiperCore.use([Autoplay]);
  return (
    <motion.section
      initial={{ y: "-100%" }}
      animate={{ y: "0%" }}
      transition={{ duration: 0.5 }}
      exit={{ y: "100" }}
      className=" md:min-h-[27vh] lg:min-h-[45vh]  w-full  bg-[#f9eff1]"
      key="partner"
      id="partner"
    >
      <MetaData title="ASR Techno" />
      <div className="h-[50%]  p-[5%]  ">
        <motion.div variants={textVariant()}>
          <h2 className="text-[#073230]  md:text-8xl text-center  sm:text-6xl  text-[45px] tracking-wider font-thin pb-[3%]">
            Our Partners
          </h2>
        </motion.div>

        <Swiper
          spaceBetween={1}
          autoplay={{ delay: 1000 }}
          loop={true}
          slidesPerView={"auto"}
          grabCursor={true}
          breakpoints={{
            350: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 6,
              spaceBetween: 10,
            },
          }}
        >
          <div className=" flex flex-row w-[100%] items-center justify-center     ">
            {plogo &&
              plogo.map((logo) => (
                <SwiperSlide key={logo.id}>
                  <div className="    flex    justify-center   cursor-pointer">
                    <img src={logo.img} alt="logo" className="w-[100%]  " />
                  </div>
                </SwiperSlide>
              ))}
          </div>
        </Swiper>
      </div>
    </motion.section>
  );
};

export default SectionWrapper(Partner, "partner");
