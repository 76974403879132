import React from "react";
import { SectionWrapper, MetaData } from "../../hoc";
import { textVariant } from "../../utils/motion";
import { motion, AnimatePresence } from "framer-motion";
import RowS from "./RowS";
import RRowS from "./RRowS";

const col1 = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686824170/collab_paawxi.png",
    des: "In today's fast-paced digital landscape, collaboration is a key ingredient for success in any business endeavor. We understand the significance of effective collaboration in achieving organizational goals. That's why we have developed innovative collaborative solutions tailored to empower businesses across industries, foster seamless communication, and drive collective growth.",
  },
];

const col2 = [
  {
    id: 1,
    img1: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686819096/collab2_wwg0tq.png",
    des1: "   Our collaboration services go beyond the traditional notion of teamwork, enabling organizations to break down silos, bridge gaps, and unlock the full potential of their workforce. By harnessing the power of advanced technologies and a deep understanding of the digital marketing landscape, we provide businesses with the tools they need to enhance collaboration, streamline processes, and achieve remarkable results.",
  },
];

const Collaboration = () => {
  return (
    <>
      <AnimatePresence>
        <motion.section
          initial={{ x: "-100%" }}
          animate={{ x: "0%" }}
          transition={{ duration: 1 }}
          exit={{ x: "100" }}
          className=" min-h-[100vh] pt-10 w-full relative top-0 left-0 bg-[#ee7b42]"
          key="collaboration"
          id="collaboration"
        >
          <MetaData title="Collaboration -- ASR Techno" />
          <div className="px-5  lg:px-28  pb-[70px] drop-shadow-sm">
            <motion.div variants={textVariant()}>
              <h2 className="text-white  md:text-8xl mb-10 text-center  sm:text-[40px]  text-[30px] tracking-wider font-thin">
                Collaboration
              </h2>
            </motion.div>

            {col1 && col1.map((rows1) => <RowS key={rows1.id} rows1={rows1} />)}
            {col2 &&
              col2.map((rrows1) => <RRowS key={rrows1.id} rrows1={rrows1} />)}
          </div>
        </motion.section>
      </AnimatePresence>
    </>
  );
};

export default SectionWrapper(Collaboration, "collaboration");
