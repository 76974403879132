import React from "react";
import { fadeIn, textVariant } from "../../utils/motion";
import { motion, AnimatePresence } from "framer-motion";

const RowS = ({ rows1 }) => {
  return (
    <div className="flex flex-col lg:flex-row w-[95%] mb-20 mx-auto">
      <div className=" w-full lg:w-1/2 ">
        <img src={rows1.img} alt="" className=" w-full lg:w-[88%]" />
      </div>

      <div className=" flex items-center justify-center w-full lg:w-1/2 ">
        <motion.p
          variants={fadeIn("", "", 0.5, 1)}
          className="mt-4   text-[21px]    content text-justify "
        >
          {rows1.des}
        </motion.p>
      </div>
    </div>
  );
};

export default RowS;
