import React from "react";
import { SectionWrapper, MetaData } from "../../hoc";
import { textVariant } from "../../utils/motion";
import { motion, AnimatePresence } from "framer-motion";
import RowS from "./RowS";
import RRowS from "./RRowS";

const dg1 = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686819093/digital_content_jxe4oh.png",
    des: "Are you looking to optimize your digital presence and establish a strong online brand? Look no further than ASR Techno that specializes in digital content creation and SEO services. With a team of seasoned professionals who are passionate about helping businesses thrive in the digital landscape, ASR Techno is dedicated to providing top-notch solutions tailored to your unique needs. ",
  },
];
const dg1_2 = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686820257/seo1_ysbkax.png",
    des: "  ASR Techno understands the importance of visibility in the vast digital landscape, and that's where our SEO expertise comes into play. Our SEO services are designed to improve your website's search engine rankings, increase organic traffic, and generate qualified leads. Through comprehensive keyword research, on-page optimization, technical SEO audits, and link building strategies, we ensure that your website stands out in search engine results pages. ",
  },
];

const dg2 = [
  {
    id: 1,
    img1: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686819103/digital_content2_s9unxv.png",
    des1: "Digital conten creation is at the core of effective online marketing strategies, and ASR Techno excels in crafting compelling, engaging, and shareable content that resonates with your target audience. Whether it's blog posts, articles, social media content, or website copy, our team of skilled content creators will ensure that your brand's voice is heard loud and clear. We combine creativity with data-driven insights to develop content that not only captures attention but also drives organic traffic, boosts engagement, and increases conversions.",
  },
];
const dg2_1 = [
  {
    id: 1,
    img1: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686820250/seo2_pvkozw.png",
    des1: "We stay up to date with the latest search engine algorithms and industry trends to provide you with effective, long-term SEO solutions. Don't let your digital presence fall behind. Choose ASR Techno as your go-to digital marketing company for exceptional digital content creation and SEO services. Take the first step towards online success and let us help you unleash your brand's full potential in the digital realm. Contact us today to discuss your digital marketing needs and embark on a transformative journey towards digital greatness.",
  },
];

const DigitalContent = () => {
  return (
    <>
      <AnimatePresence>
        <motion.section
          initial={{ x: "-100%" }}
          animate={{ x: "0%" }}
          transition={{ duration: 1 }}
          exit={{ x: "100" }}
          className=" min-h-[100vh] pt-10 w-full relative top-0 left-0 bg-[#ee7b42]"
          key="digital"
          id="digital"
        >
          <MetaData title="Digital Content Creation & SEO -- ASR Techno" />

          <div className="px-5  lg:px-28  pb-[70px] drop-shadow-sm">
            <motion.div variants={textVariant()}>
              <h2 className="text-white  md:text-8xl text-center mb-10 sm:text-[40px]  text-[30px] tracking-wider font-thin">
                Digital Content Creation & SEO
              </h2>
            </motion.div>

            <motion.div variants={textVariant()}>
              <h2 className="text-white w-[95%] mx-auto mb-10 text-left  sm:text-[40px]  text-[30px] tracking-wider font-thin mt-16">
                Digital Content Creation
              </h2>
            </motion.div>

            {dg1 && dg1.map((rows1) => <RowS key={rows1.id} rows1={rows1} />)}
            {dg2 &&
              dg2.map((rrows1) => <RRowS key={rrows1.id} rrows1={rrows1} />)}

            <motion.div variants={textVariant()}>
              <h2 className="text-white   text-right my-10 w-[95%] mx-auto  sm:text-[40px]  text-[30px] tracking-wider font-thin">
                SEO (search engine optimization)
              </h2>
            </motion.div>
            {dg1_2 &&
              dg1_2.map((rows1) => <RowS key={rows1.id} rows1={rows1} />)}
            {dg2_1 &&
              dg2_1.map((rrows1) => <RRowS key={rrows1.id} rrows1={rrows1} />)}
          </div>
        </motion.section>
      </AnimatePresence>
    </>
  );
};

export default SectionWrapper(DigitalContent, "digital");
