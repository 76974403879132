import React from "react";
import { fadeIn } from "../../utils/motion";
import { motion } from "framer-motion";

const RRowS = ({ rrows1 }) => {
  return (
    <div className="flex flex-col lg:flex-row-reverse w-[95%] my-10 mx-auto">
      <div className="w-full lg:w-1/2 ">
        <img src={rrows1.img1} alt="" className=" w-full lg:w-[88%]  lg:ml-auto " />
      </div>

      <div className=" flex  items-center justify-center w-full lg:w-1/2 ">
        <motion.p
          variants={fadeIn("", "", 0.5, 1)}
          className="mt-4   text-[21px]    content text-justify "
        >
          {rrows1.des1}
        </motion.p>
      </div>
    </div>
  );
};

export default RRowS;
