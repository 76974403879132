import React from "react";
import { CgFacebook } from "react-icons/cg";
import {
  AiFillYoutube,
  AiOutlineInstagram,
  AiFillLinkedin,
} from "react-icons/ai";
import Logo from "../../Images/logo.png";
import Isflogo from "../../Images/isflogo.png";
import India from "../../Images/india.png";

const Footer = () => {
  return (
    <div className=" bg-[#fafafa] px-0 sm:px-10   ">
      <footer className="text-[#073230] content ">
        <div className="container px-5 pt-12 mx-auto flex md:items-center lg:items-start justify-between lg:flex-row md:flex-nowrap flex-wrap flex-col">
          <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
            <a
              href="/"
              className="flex  items-center md:justify-start justify-center text-gray-900"
            >
              <img src={Logo} alt="Asr Techno" className="h-12 invert" />
              {/* <span className="ml-3 text-xl text-[#073230] content">ASR TECHNO</span> */}
            </a>
            <p className="mt-4 text-sm text-[#073230] content">
              WE CREATE, YOU SUCCEED
            </p>
            <p className="mt-4 text-sm text-[#073230] content">Follow us on</p>
            <span className="inline-flex sm:ml-auto  mt-5 justify-center sm:justify-start space-x-8">
              <a
                href="https://www.facebook.com/asrtechobyrajpurohit/"
                className="text-blue-700 transition duration-300 shadow-xl rounded-full p-2 bg-white hover:bg-blue-500 hover:text-[#073230]"
              >
                <CgFacebook />
              </a>
              <a
                href="https://www.youtube.com/@asrtechno"
                className="hover:text-[#073230] text-red-500 bg-white hover:bg-red-500 transition duration-300 shadow-2xl shadow-blue-900 rounded-full p-2"
              >
                <AiFillYoutube />
              </a>
              <a
                href="https://www.instagram.com/asr_techno/"
                className="hover:text-[#073230] bg-white hover:bg-pink-500 text-pink-500 transition duration-300 shadow-xl rounded-full p-2"
              >
                <AiOutlineInstagram />
              </a>
              <a
                href="https://in.linkedin.com/company/asr-techno"
                className="hover:text-[#073230] hover:bg-blue-500 text-blue-500 bg-white transition duration-300 shadow-xl rounded-full p-2"
              >
                <AiFillLinkedin />
              </a>
            </span>
          </div>
          <div className="flex-grow flex flex-wrap lg:pl-20 -mb-10 lg:mt-0 mt-10 md:text-left text-center">
            <div className="lg:w-1/4 md:w-1/2 w-full px-4 ml-auto">
              <h2 className="title-font font-medium text-[#073230] tracking-widest text-md mb-3">
                Services
              </h2>
              <nav className="list-none flex flex-col mb-10 text-[#073230] text-sm ">
                <a href="/services/collaboration">
                  <li className="content">Collaboration</li>
                </a>

                <a href="/services/activation&events">
                  <li className="content">Activation and events</li>
                </a>

                <a href="/services/digital-content-creation&seo">
                  <li className="content">Digital content creation and SEO</li>
                </a>

                <a href="/services/e-commerce-strategy&execution">
                  <li className="content">E-Commerce Strategy and Execution</li>
                </a>

                <a href="/services/Influencer&Relationship&Celebrity-Management&pr">
                  <li className="content">
                    Influencer, Relationship, Celebrity Management and PR
                  </li>
                </a>
              </nav>
            </div>

            <div className="lg:w-1/4 md:w-1/2 w-full px-4 ml-auto">
              <h2 className="title-font font-medium text-[#073230] tracking-widest text-md mb-3">
                Services
              </h2>
              <nav className="list-none mb-10 text-[#073230] flex flex-col-reverse text-sm">
                <a href="/services/video-production&photography">
                  <li className="content">Video Production and Photography</li>
                </a>

                <a href="/services/Integrated-marketing-solutions">
                  <li className="content">Integrated Marketing Solutions</li>
                </a>

                <a href="/services/media-planning&buying">
                  <li className="content">Media Planning and Buying</li>
                </a>

                <a href="/services/technology&design">
                  <li className="content">Technology and Design</li>
                </a>

                <a href="/services/virtual-reality">
                  <li className="content">Virtual Reality</li>
                </a>
              </nav>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full px-4 ml-auto">
              <h2 className="title-font font-medium text-[#073230] tracking-widest text-md mb-3">
                Discover ASR Techno
              </h2>
              <nav className="list-none mb-10 text-sm">
                <li>
                  <a
                    href="/"
                    className="text-[#073230] content hover:text-[#33cccc]"
                  >
                    Home
                  </a>
                </li>

                <li>
                  <a
                    href="#about"
                    className="text-[#073230] content hover:text-[#33cccc]"
                  >
                    About
                  </a>
                </li>

                <li>
                  <a
                    href="#service"
                    className="text-[#073230] content hover:text-[#33cccc]"
                  >
                    Services
                  </a>
                </li>

                <li>
                  <a
                    href="#testMonials"
                    className="text-[#073230] content hover:text-[#33cccc]"
                  >
                    TestMonials
                  </a>
                </li>

                <li>
                  <a
                    href="#contact"
                    className="text-[#073230] content hover:text-[#33cccc]"
                  >
                    Contact Us
                  </a>
                </li>
              </nav>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full px-4 ml-auto">
              <h2 className="title-font font-medium text-[#073230] tracking-widest text-md mb-3">
                Contact US
              </h2>
              <nav className="list-none mb-10 text-[#073230] text-sm">
                <h2 className="content">
                  {" "}
                  Address : Malviya Nagar, Jaipur, Rajasthan (302017)
                </h2>
                <h2 className="content"> Contact Number : 7357094260 </h2>
                <h2 className="content hover:text-blue-500 cursor-pointer">
                  {" "}
                  Email Id :{" "}
                  <a href="mailto:info@asrtechno.in" className="content">
                    info@asrtechno.in
                  </a>{" "}
                </h2>

                <div className="container    flex flex-row justify-start items-center gap-4  m ">
                  <span className="pt-2 tracking-wider text-start mt-5">
                    Incubated with
                  </span>
                  <img
                    src={Isflogo}
                    alt="islogo"
                    className="w-[50%] md:w-[30%]  mt-5 invert"
                  />
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div className="bg-transparent ">
          <div className="container  py-4 px-2 flex flex-row gap-2 items-center justify-center ">
            <p className="text-[#073230] text-sm   content  text-center">
              © 2023 ASRTechno Proudly Made in India
            </p>
            <img src={India} alt="india" className="w-[10.5%] md:w-[1.5%]" />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
