import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Home,
  Footer,
  Header,
  About,
  ServicesSlide,
  Contact,
} from "./Components";
import { AnimatePresence } from "framer-motion";
import ActivationAndEvents from "./Components/ServiceDetails/ActivationAndEvents";
import DigitalContent from "./Components/ServiceDetails/DigitalContent";
import ECommerceStrategy from "./Components/ServiceDetails/ECommerceStrategy";
import Influencer from "./Components/ServiceDetails/Influencer";
import Collaboration from "./Components/ServiceDetails/Collaboration";
import VR from "./Components/ServiceDetails/VR";
import VPP from "./Components/ServiceDetails/VPP";
import MPB from "./Components/ServiceDetails/MPB";
import IMS from "./Components/ServiceDetails/IMS";
import TD from "./Components/ServiceDetails/TD";
import { MetaData } from "./hoc";



function App() {
  return (
    <BrowserRouter>
     <MetaData title=" Home -- ASR Techno" />
      <div className="relative z-0 bg-white ">
        <Header />
        {/* <div className="relative z-0 "> */}
          <AnimatePresence>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/service" element={<ServicesSlide />} />
              <Route path="/contact-us" element={<Contact />} />
              <Route
                path="/services/activation&events"
                element={<ActivationAndEvents />}
              />
              <Route
                path="/services/digital-content-creation&seo"
                element={<DigitalContent />}
              />
              <Route
                path="/services/e-commerce-strategy&execution"
                element={<ECommerceStrategy />}
              />
              <Route
                path="/services/Influencer&Relationship&Celebrity-Management&pr"
                element={<Influencer />}
              />
              <Route
                path="/services/collaboration"
                element={<Collaboration />}
              />
              <Route path="/services/virtual-reality" element={<VR />} />
              <Route
                path="/services/video-production&photography"
                element={<VPP />}
              />
              <Route path="/services/media-planning&buying" element={<MPB />} />
              <Route
                path="/services/Integrated-marketing-solutions"
                element={<IMS />}
              />
              <Route path="/services/media-planning&buying" element={<MPB />} />
              <Route path="/services/technology&design" element={<TD />} />
             
            </Routes>
          </AnimatePresence>
        {/* </div> */}
        <div className="relative z-50">
        <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
