import React from "react";
import { SectionWrapper, MetaData } from "../../hoc";
import { fadeIn } from "../../utils/motion";
import { motion } from "framer-motion";

import "./ServicesSlide.css";

const data=[
  {
      id:1,
      img:"https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686649785/event1_dwtmwr.gif",
      serviceh:"Activation and events ",
      serviced:"  We help our clients create engaging experiences that connect with their target audience through innovative technology and creative solutions.",
      pathurl: "/services/activation&events"
  },
  {
      id:2,
      img:"https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686649781/e-commerce1_x1vxli.gif",
      serviceh:"E-Commerce Strategy",
      serviced:"  We help businesses create and implement effective online selling strategies that maximize revenue and customer satisfaction.",
      pathurl: "/services/e-commerce-strategy&execution"
  },
  {
      id:3,
      img:"https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686649786/seo1_yiuztt.gif",
      serviceh:"Digital Marketing ",
      serviced:"  Our team of experts is dedicated to providing high-quality content and optimizing websites to enhance online visibility and drive traffic.",
      pathurl: "/services/digital-content-creation&seo"
  },
  {
      id:4,
      img:"https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686650396/management1_ppq25f.gif",
      serviceh:"PR Management",
      serviced:"We connect brands with relevant influencers and celebrities and create strategic PR campaigns that generate positive media coverage.",
      pathurl: "/services/Influencer&Relationship&Celebrity-Management&pr"
  },
  {
      id:5,
      img:"https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686649786/marketing1_iqwjn9.gif",
      serviceh:"Integrated Marketing ",
      serviced:" We leverage our understanding of consumer behavior and data-driven insights to ensure our clients' messages reach the right audience.",
      pathurl: "/services/Integrated-marketing-solutions"
  },
  {
      id:6,
      img:"https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686649782/buy1_ahh0ex.gif",
      serviceh:"Media Planning",
      serviced:" We help businesses develop and execute effective media strategies that reach their target audience through various media channels.",
      pathurl: "/services/media-planning&buying"
  },
  {
      id:7,
      img:"https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686649782/design1_n4l0yv.gif",
      serviceh:"Technology and Design",
      serviced:" We provide customized web and mobile applications, UI/UX design, and other technology solutions that are tailored to their unique needs.",
      pathurl: "/services/technology&design"
  },
  {
      id:8,
      img:"https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686649787/vr1_qfupdl.gif",
      serviceh:"Virtual Reality",
      serviced:" With our expertise in VR technology, we can help you create a unique and memorable experience that will leave an impression on your audience.",
      pathurl: "/services/virtual-reality"
  },
  {
      id:9,
      img:"https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686649789/photo1_bjw9yl.gif",
      serviceh:"Production House",
      serviced:"Our team specializes in creating high-quality visual content that tells your brand's story and captures your audience's attention.",
      pathurl: "/services/video-production&photography"
  },
  {
      id:10,
      img:"https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686649781/coll1_ljngqn.gif",
      serviceh:"Collaboration",
      serviced:"By leveraging the latest collaboration tools and technologies, we enable effective communication and project management, resulting in high-quality deliverables.",
      pathurl: "/services/collaboration"
  },
]

const Services = () => {

  return (
    <section id="service"  >
       <MetaData title="ASR Techno" />
      
      <div className=" py-[2%]  bg-[#f9eff1] text-[#5751bb] px-0 md:px-9   min-h-[130vh] ">
        <motion.p
          variants={fadeIn("", "", 0.2, 1)}
          className=" text-center sm:text-6xl text-[40px] md:text-8xl   py-16  tracking-wider   text-[#073230]  "
        >
          Services We Offer{" "}
        </motion.p>

        <div className="flex flex-wrap pl-0 md:pl-[10px] px-[0em]   justify-center ">

        {data && data.map((item) => <Card key={item.id} item={item} />)}
        </div>
      </div>
      
    </section>
  );
};

const Card = ({ item }) => {
  return (
    <a href={item.pathurl}>
  <div className="card">
    <div className="wrapper">
    <img src={item.img}  style={{width: "4.5in", height: "4in"}}/>

    </div>
    <h3 className="title content px-2 uppercase">
              {item.serviceh}
            </h3>
    <img src={item.img} className="character"  style={{width: "5in", height: "4.5in"}}/>
  </div>

   </a>
  );
};

export default SectionWrapper(Services, "service");
