import React, { useEffect } from "react";
import IMG3 from "../../Images/img3.png"
import { SectionWrapper, MetaData } from "../../hoc";
import { slideIn } from "../../utils/motion";
import { motion } from "framer-motion";

const Quote = () => {
  return (
    <section id="quote">
       <MetaData title="ASR Techno" />
      <div className="py-20   bg-[#cecece]  text-[#915151]  flex flex-col-reverse lg:flex-row">

        <div className=" w-full lg:w-[70%]">

        <motion.p
          variants={slideIn("left", "", 0.1, 1)}
          className="text-[40px] sm:text-[42px] text-justify md:text-8xl  md:py-32 p-10 lg:px-20 w-full  lg:leading-normal leading-none  tracking-normal "
        >
          {" "}
          Digital marketing is not an option, it's a necessity for any business
          that wants to thrive in the digital age.
        </motion.p>   
        </div>

<div className=" w-full lg:w-[30%] lg:pr-10 px-5">
  <img src={IMG3} alt="" />
</div>

     
             
      </div>
    </section>
  );
};

export default SectionWrapper(Quote, "quote");
