import React from "react";
import { SectionWrapper, MetaData } from "../../hoc";
import { fadeIn, textVariant } from "../../utils/motion";
import { motion, AnimatePresence } from "framer-motion";
import RowS from "./RowS";
import RRowS from "./RRowS";

const ec1 = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686819104/e_comm1_kcd0ax.png",
    des: "   We are offering complete e-commerce strategy and execution services to drive your online business to new heights. With the rapid growth of e-commerce, it has become imperative for businesses to establish a strong online presence and maximize their digital potential. That's where our expertise comes in. We understand the unique challenges that e-commerce businesses face in the highly competitive digital landscape. Our team of skilled professionals is equipped with in-depth knowledge and experience in crafting tailored e-commerce strategies that align with your brand's goals and objectives. We take a holistic approach, combining market research, competitor analysis, and consumer behavior insights to develop a winning e-commerce strategy that resonates with your target audience.",
  },
];

const ec2 = [
  {
    id: 1,
    img1: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686819095/e_comm2_qvb8un.png",
    des1: "  Our experts meticulously analyze your product catalog, optimize product descriptions, and implement effective pricing strategies to maximize conversions and boost sales. But our services don't stop at strategy development. We go the extra mile to ensure flawless execution and implementation of your e-commerce strategy. Our team leverages the latest technology and platforms to create stunning, user-friendly e-commerce websites that provide seamless browsing and shopping experiences for your customers. We optimize your website for search engines, implement robust security measures, and integrate secure payment gateways to instill trust and confidence in your customers. Let us move your online business to unprecedented success in the dynamic world of e-commerce. Together, we'll make your e-commerce dreams a reality.",
  },
];

const ECommerce = () => {
  return (
    <>
      <AnimatePresence>
        <motion.section
          initial={{ x: "-100%" }}
          animate={{ x: "0%" }}
          transition={{ duration: 1 }}
          exit={{ x: "100" }}
          className=" min-h-[100vh] pt-10 w-full relative top-0 left-0 bg-[#ee7b42]"
          key="ecommerce"
          id="ecommerce"
        >
          <MetaData title="E-Commerce Strategy & Execution -- ASR Techno" />
          <div className="px-5  lg:px-28  pb-[70px] drop-shadow-sm">
            <motion.div variants={textVariant()}>
              <h2 className="text-white  md:text-8xl mb-10 text-center  sm:text-[40px]  text-[30px] tracking-wider font-thin">
                E-Commerce Strategy & Execution
              </h2>
            </motion.div>

            {ec1 && ec1.map((rows1) => <RowS key={rows1.id} rows1={rows1} />)}
            {ec2 &&
              ec2.map((rrows1) => <RRowS key={rrows1.id} rrows1={rrows1} />)}
          </div>
        </motion.section>
      </AnimatePresence>
    </>
  );
};

export default SectionWrapper(ECommerce, "ecommerce");
