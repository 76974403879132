import {
    ADMIN_TESTIMONIAL_REQUEST,
    ADMIN_TESTIMONIAL_SUCCESS,
    ADMIN_TESTIMONIAL_FAIL,
 
    CLEAR_ERRORS,
  } from "../constants/contactConstants";



export const testimonialsReducer = (state = { testimonials: [] }, action) => {
    switch (action.type) {
      case ADMIN_TESTIMONIAL_REQUEST:
        return {
          loading: true,
          contacts: [],
        };
  
      case ADMIN_TESTIMONIAL_SUCCESS:
        return {
          loading: false,
          testimonials: action.payload,
        };
  
      case ADMIN_TESTIMONIAL_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };