import React, { Fragment } from "react";
import { CgFacebook } from "react-icons/cg";
import Logo from "../../Images/logo.png"
import IMG from "../../Images/img.png"
import {
  AiFillYoutube,
  AiOutlineInstagram,
  AiFillLinkedin,
} from "react-icons/ai";
import {
  About,
  Client,
  Contact,
  Partner,
  ProjectCount,
  Quote,
  Testmonial,
  ServicesSlide,
} from "../../Components";
import "./Home.css";
import TextVideo from "./TextVideo";
import { MetaData } from "../../hoc";


const Home = () => {
  return (
    <Fragment>
      <div
        key="home"
        className=" flex flex-col-reverse md:flex-row   lg:p-0 lg:pl-16  sticky top-0 left-0 w-full min-h-[100vh] bg-[#fafafa]  "
      >
       
       
       <a href="/">
        <img src={Logo} className="logo invert w-[15%] lg:w-[5%]" alt="ASR Techno"/></a>
       

        <MetaData title=" ASR Techno" />
        <div>   <img
          className="image1 w-[30%] lg:w-[8%]"
          src="https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686977016/Untitled_design_58_e9dlqq.png"
          alt=""
        />
        
        </div>

        <div className="flex flex-col lg:flex-row">
        <div className="flex-1 p-10 py-20 flex flex-col text-white mx-0 ml-0 lg:ml-20 justify-center items-center lg:items-start pt-[4rem] md:pt-[7rem] lg:pt-[2rem] gap-8 z-10 ">
          <div className="flex  items-center  justify-center md:justify-start    ">
            <ul className="icons">
              <li>
                <a href="https://www.facebook.com/asrtechobyrajpurohit/">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span className="fa ">
                    <CgFacebook />
                  </span>
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/@asrtechno">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span className="fa ">
                    <AiFillYoutube />
                  </span>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/asr_techno/">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span className="fa ">
                    <AiOutlineInstagram />
                  </span>
                </a>
              </li>
              <li>
                <a href="https://in.linkedin.com/company/asr-techno">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span className="fa ">
                    <AiFillLinkedin />
                  </span>
                </a>
              </li>
            </ul>
          </div>

          <div className=" text-center  lg:text-start text-[3.5rem] lg:text-[9rem] leading-[4rem] sm:leading-[5rem] md:leading-[10rem]  xl:text-7xl 2xl:text-[4.5rem]  flex flex-col ">
            <p className="font-thin   text-[#073230] ">
              we <span className="text-[4rem] md:text-[9rem] ml-0 md:-ml-5">Create</span>
            </p>
            <p className="font-thin   text-[#073230]
              pt-0 md:-pt-7">
              you <span className="text-[4rem] md:text-[9rem] ml-0 md:-ml-5">Succeed.</span>
            </p>
          </div>
          <p className="text-center md:text-start sm:text-[14px] md:text-[22px]   font-thin  text-black -pt-6 content">
            Welcome to{" "}
            <span className="text-[#073230] font-semibold text-[30px] content">
              ASR Techno
            </span>
            , Where Creativity meets technology
          </p>

          <div className="flex flex-col md:flex-row gap-3  md:gap-[5.5rem] pt-[2em] items-center justify-center ml-[4rem] md:justify-start">
            <a href="#about" className="btn  tracking-wider">
              <span className="text  tracking-wider">Text</span>
              <span className="flip-front   tracking-wider">Explore More</span>
              <span className="flip-back  tracking-wider">Click Me</span>
            </a>

            <a href="#contact" className="btn tracking-wider">
              <span className="text tracking-wider">Text</span>
              <span className="flip-front tracking-wider  ">Contact Us</span>
              <span className="flip-back tracking-wider ">Click Me</span>
            </a>
          </div>
        </div>

<div className=" w-full lg:w-1/2">
  <img  src={IMG} alt=""  className="w-[100%]  ml-0 lg:w-[70%] lg:ml-auto"/>
</div>
</div>
      </div>

      <div >
        <ServicesSlide />
      </div>
      <div className="sticky top-0 left-0">
        <About />
      </div>
      <div className="sticky top-0 left-0   ">
        <TextVideo />
      </div>

      <div>
        <ProjectCount />
      </div>

<div className="sticky top-0 left-0">
<Client />
</div>

      <div >
        <Quote />
      </div>

      <div className="sticky top-0 left-0"><Testmonial /></div>

      <div className="relative top-0 left-0">
        <Contact />
      </div>
      <Partner />
    </Fragment>
  );
};

export default Home;
