import {
    CONTACT_USER_REQUEST,
    CONTACT_USER_SUCCESS,
    CONTACT_USER_FAIL,
    CLEAR_ERRORS,
  } from "../constants/contactConstants";

  import axios from "axios";

// Contact Us Form
export const contact = (contactData) => async (dispatch) => {
    try {
      dispatch({ type: CONTACT_USER_REQUEST });
  
      const config = {
        headers: { "Content-Type": "application/json" },
      };
  
      const { data } = await axios.post(
        `/api/v1/contact/new`,
        contactData,
        config
      );
  
      dispatch({
        type: CONTACT_USER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CONTACT_USER_FAIL,
        payload: error.response.data.message,
      });
    }
  };


    // Clearing Errors
    export const clearErrors = () => async (dispatch) => {
        dispatch({ type: CLEAR_ERRORS });
      };