import React from "react";
import { SectionWrapper, MetaData } from "../../hoc";
import { fadeIn, textVariant } from "../../utils/motion";
import { motion, AnimatePresence } from "framer-motion";
import RowS from "./RowS";
import RRowS from "./RRowS";

const td1 = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686819098/design_wha1fg.png",
    des: " Our team of skilled professionals combines technical skill with artistic flair to deliver tailored solutions that cater to your unique business needs. Whether you require website development, mobile app design, UI/UX optimization, or custom software development, we've got you covered. Our cutting-edge technology solutions are designed to enhance efficiency, streamline operations, and empower your brand in the digital landscape.",
  },
];
const td2 = [
  {
    id: 1,
    img1: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686819093/design2_hr2fsp.png",
    des1: "ASR Techno offers a complete range of technology and design services that drive innovation, elevate user experiences, and amplify your online presence. With our expertise in blending technology and design seamlessly, we are here to help you unlock the full potential of your digital strategy and propel your business to new heights.",
  },
];
const td3 = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686820964/design3_pe4nls.png",
    des: "From eye-catching logos and branding elements to engaging website interfaces and intuitive user experiences, we create design solutions that align with your vision and drive meaningful connections with your audience.",
  },
];
const td4 = [
  {
    id: 1,
    img1: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686820977/design4_f0nu3s.png",
    des1: "  We are dedicated to delivering superior results, staying ahead of industry trends, and keeping your business at the forefront of digital innovation. Experience the transformative power of technology and design with ASR Techno. Let us help you shape your digital presence, drive growth, and achieve remarkable success in the ever-evolving digital landscape.",
  },
];

const TD = () => {
  return (
    <>
      <AnimatePresence>
        <motion.section
          initial={{ x: "-100%" }}
          animate={{ x: "0%" }}
          transition={{ duration: 1 }}
          exit={{ x: "100" }}
          className=" min-h-[100vh] pt-10 w-full relative top-0 left-0 bg-[#ee7b42]"
          key="technologydesign"
          id="technologydesign"
        >
          <MetaData title=" Technology and Design -- ASR Techno" />

          <div className="px-5  lg:px-28  pb-[70px] drop-shadow-sm">
            <motion.div variants={textVariant()}>
              <h2 className="text-white  md:text-8xl text-center mb-10 sm:text-[40px]  text-[30px] tracking-wider font-thin">
                Technology and Design
              </h2>
            </motion.div>

            {td1 && td1.map((rows1) => <RowS key={rows1.id} rows1={rows1} />)}
            {td2 &&
              td2.map((rrows1) => <RRowS key={rrows1.id} rrows1={rrows1} />)}
            {td3 && td3.map((rows1) => <RowS key={rows1.id} rows1={rows1} />)}
            {td4 &&
              td4.map((rrows1) => <RRowS key={rrows1.id} rrows1={rrows1} />)}
          </div>
        </motion.section>
      </AnimatePresence>
    </>
  );
};

export default SectionWrapper(TD, "technologydesign");
