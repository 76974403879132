import React from "react";
import { SectionWrapper, MetaData } from "../../hoc";
import { fadeIn, textVariant } from "../../utils/motion";
import { motion, AnimatePresence } from "framer-motion";
import RowS from "./RowS";
import RRowS from "./RRowS";

const in1 = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686819090/ir1_pvmlbr.png",
    des: "  Our influencer relationship management services go beyond mere collaborations. We carefully analyze your brand's objectives and target audience to identify the most suitable influencers who align with your brand values and can effectively promote your products or services.",
  },
];
const in3 = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686819104/celebrity_gjdngd.png",
    des: " As part of our celebrity management services, we leverage our strong industry connections and expertise to secure partnerships with notable figures across various domains. From renowned actors and musicians to sports icons and social media influencers, we have the know-how to navigate the celebrity landscape and create impactful associations that enhance your brand's credibility and visibility.",
  },
];

const in2 = [
  {
    id: 1,
    img1: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686819099/pr_vi18w0.png",
    des1: "   ASR Techno also excels in public relations (PR) strategies tailored to the digital age. We understand that in today's fast-paced world, effective PR goes beyond traditional media channels. We harness the power of social media, digital platforms, and online publications to craft compelling narratives about your brand, driving positive visibility and establishing your authority in the market. Our team of digital marketing experts understands the intricacies of influencer relationships, celebrity endorsements, and PR campaigns. We work closely with you to develop customized strategies that align with your brand's vision and objectives. With our meticulous approach, we ensure that every collaboration and PR initiative is well-managed, delivering optimal results and maximizing your return on investment (ROI). Don't let your brand get lost in the digital noise. Partner with ASR Techno to unlock the potential of influencer relationships, celebrity endorsements, and PR strategies that will propel you  brand to the forefront of your industry.",
  },
];

const Influencer = () => {
  return (
    <>
      <AnimatePresence>
        <motion.section
          initial={{ x: "-100%" }}
          animate={{ x: "0%" }}
          transition={{ duration: 1 }}
          exit={{ x: "100" }}
          className=" min-h-[100vh] pt-10 w-full relative top-0 left-0 bg-[#ee7b42]"
          key="influencer"
          id="influencer"
        >
          <MetaData title=" Influencer Relationship, Celebrity Management & PR -- ASR Techno" />
          <div className="px-5  lg:px-28  pb-[70px] drop-shadow-sm">
            <motion.div variants={textVariant()}>
              <h2 className="text-white  md:text-8xl text-center mb-10 sm:text-[40px]  text-[30px] tracking-wider font-thin">
                Influencer Relationship, Celebrity Management & PR
              </h2>
            </motion.div>

            <motion.p
              variants={fadeIn("", "", 0.5, 1)}
              className="mt-6   text-[18px] text-center   content "
            >
              Are you looking to elevate your brand's presence and reach new
              heights in the digital world? Look no further than ASR Techno,We
              specialize in influencer relationship management and celebrity PR
              services. With our expertise and strategic approach, we can help
              you forge powerful connections with influential personalities and
              celebrities, harnessing their reach and impact to drive your
              brand's success.
            </motion.p>

            <motion.div variants={textVariant()}>
              <h2 className="text-white  my-10 text-left  sm:text-[40px]  text-[30px] tracking-wider font-thin w-[95%] mx-auto">
                Influencer Relationship
              </h2>
            </motion.div>
            {in1 && in1.map((rows1) => <RowS key={rows1.id} rows1={rows1} />)}

            <motion.div variants={textVariant()}>
              <h2 className="text-white  my-10 text-right  sm:text-[40px]  text-[30px] tracking-wider font-thin w-[95%] mx-auto">
                Public Relations
              </h2>
            </motion.div>
            {in2 &&
              in2.map((rrows1) => <RRowS key={rrows1.id} rrows1={rrows1} />)}

            <motion.div variants={textVariant()}>
              <h2 className="text-white  my-10 text-left  sm:text-[40px]  text-[30px] tracking-wider font-thin w-[95%] mx-auto">
                Celebrity Management
              </h2>
            </motion.div>
            {in3 && in3.map((rows1) => <RowS key={rows1.id} rows1={rows1} />)}
          </div>
        </motion.section>
      </AnimatePresence>
    </>
  );
};

export default SectionWrapper(Influencer, "influencer");
