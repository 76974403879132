import React from "react";
import { SectionWrapper, MetaData } from "../../hoc";
import { fadeIn, textVariant } from "../../utils/motion";
import { motion, AnimatePresence } from "framer-motion";
import IMG1 from "../../Images/img1.png"

const About = () => {
  return (
    <>
      <MetaData title="ASR Techno" />
      <AnimatePresence>
        <motion.section
          initial={{ x: "-100%" }}
          animate={{ x: "0%" }}
          transition={{ duration: 1 }}
          exit={{ x: "100" }}
          className=" min-h-fit  lg:min-h-[100vh]  lg:pb-0 pt-10 w-full  bg-[#fafafa] "
          key="about"
          id="about"
        >
          <div className=" pt-5   lg:pb-[70px] ">
         


<div className="flex flex-col lg:flex-row-reverse ">

  <div className=" w-[90%] lg:w-1/2 flex justify-center items-center flex-col mx-auto pr-0 lg:pr-20 ">

  <motion.div variants={textVariant()}>
              <h2 className="text-[#053132]  md:text-8xl text-right  sm:text-6xl  text-[45px]  tracking-wider font-thin pb-10">
                Overview
              </h2>
            </motion.div>

     <motion.p
              variants={fadeIn("", "", 0.5, 1)}
              className="   text-[21px] text-justify   text-black content"
            >
              Welcome to ASR Techno We are not just another IT-based advertising
              agency, we are a team of tech enthusiasts and creative masterminds
              who strive to bring our client's visions to life. Our mission is
              to help businesses achieve their goals through effective digital
              marketing strategies and cutting-edge technology solutions. At ASR
              Techno, we believe collaboration and innovation are the keys to
              success. That's why we work closely with our clients to understand
              their unique needs and goals and tailor our services to deliver
              measurable results. We take pride in our ability to think outside
              the box and come up with innovative solutions that set our clients
              apart from the competition.
            </motion.p>
    

</div>

<div className="w-[90%] lg:w-1/2 ">
  <img src={IMG1} alt="" className=" mr-auto w-[100%] lg:w-[63%]" />
</div>

          
          </div>
          </div>
         
        </motion.section>
      </AnimatePresence>
    </>
  );
};

export default SectionWrapper(About, "about");
