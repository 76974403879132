import React from "react";
import { SectionWrapper, MetaData } from "../../hoc";
import { textVariant } from "../../utils/motion";
import { motion, AnimatePresence } from "framer-motion";
import RowS from "./RowS";
import RRowS from "./RRowS";

const ae = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686649785/event1_dwtmwr.gif",
    des: "Are you looking to make your brand's activation and events a resounding success? Look no further than ASR Techno, a leading digital marketing company offering top-notch activation and events services. With our expertise and creative approach, we bring your brand's vision to life, creating unforgettable experiences that captivate your target audience and leave a lasting impact. At ASR Techno, we understand the power of activations and events in creating meaningful connections between brands and consumers. Our team of skilled professionals works closely with you to understand your brand identity, objectives, and target market.",
  },
];

const rae = [
  {
    id: 1,
    img1: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686999734/ae1_obvomy.jpg",
    des1: "  We then design and execute strategic activations and events that align seamlessly with your brand's values, ensuring maximum engagement and brand exposure. From product launches and experiential marketing campaigns to trade shows and corporate events, we handle every aspect of the process with precision and creativity. Our comprehensive services include event conceptualization, planning, logistics, venue selection, event production, branding, promotion, and post-event analysis. We take care of all the intricate details, allowing you to focus on what you do best growing your business. Partner with ASR Techno for your activation and events needs, and witness the transformative power of a well-executed brand experience. Contact us today to discuss your requirements and let us help you create extraordinary moments that elevate your brand to new heights.",
  },
];

const Activation = () => {
  return (
    <>
      <AnimatePresence>
        <motion.section
          initial={{ x: "-100%" }}
          animate={{ x: "0%" }}
          transition={{ duration: 1 }}
          exit={{ x: "100" }}
          className=" min-h-[100vh] pt-10 w-full relative top-0 left-0 bg-[#ee7b42]"
          key="activation"
          id="activation"
        >
          <MetaData title="Activation & Events -- ASR Techno" />
          <div className="px-5  lg:px-28  pb-[70px] drop-shadow-sm">
            <motion.div variants={textVariant()}>
              <h2 className="text-white  md:text-8xl text-center mb-10  sm:text-[40px]  text-[30px] tracking-wider font-thin">
                Activation & Events
              </h2>
            </motion.div>

            {ae && ae.map((rows1) => <RowS key={rows1.id} rows1={rows1} />)}
            {rae &&
              rae.map((rrows1) => <RRowS key={rrows1.id} rrows1={rrows1} />)}
          </div>
        </motion.section>
      </AnimatePresence>
    </>
  );
};

export default SectionWrapper(Activation, "activation");
