import React, { useState } from "react";
import { slideIn } from "../../utils/motion";
import { motion } from "framer-motion";
import { SectionWrapper, MetaData } from "../../hoc";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import "./ProjectCount.css";
import SM from "../../Images/sm.png"
import WS from "../../Images/ws.png"
import EN from "../../Images/en.png"
import PG from "../../Images/pg.png"

const ProjectCount = () => {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <section id="our-work">
      <MetaData title="ASR Techno" />
      <div className="pb-20 bg-[#f9eff1] text-[#1c2042]  min-h-[100vh]">
       

        <motion.p
          variants={slideIn("left", "", 0.1, 1)}
          className="text-center  text-[45px] sm:text-6xl md:text-8xl  pb-10 pt-20 px-0 md:px-20   tracking-wider "
        >
          {" "}
          Our Work
        </motion.p>

        <div className="containerimg text-[#1c2042] ">
          <div className="box  bg-[#dddddd]">
            <img src={SM} />
            <span>Social Media</span>
          </div>
          <div className="box bg-[#dddddd]">
            <img src={WS} />
            <span>Web Developement</span>
          </div>
          <div className="box bg-[#dddddd]">
            <img src={PG} />
            <span>Campaign Shoot</span>
          </div>
          <div className="box bg-[#dddddd]">
            <img src={EN} />
            <span>Events</span>
          </div>
        </div>


        <ScrollTrigger
          onEnter={() => setCounterOn(true)}
          onExit={() => setCounterOn(false)}
        >
          <div className="flex flex-wrap w-full    mx-auto mb-5 gap-3 px-0 md:px-5 lg:px-0 bg-[#f9eff1] text-[#1c2042] pt-10 pb-7">
            <div className="flex flex-col w-[40%] md:w-[22%] lg:w-[24%] justify-start items-start    py-6 rounded-[30px] mx-auto">
              <div className="px-5 mx-auto text-6xl lg:text-8xl">
                {counterOn && (
                  <CountUp start={0} end={5} duration={2} delay={0} />
                )}
                +
              </div>
              <div className="px-5 mx-auto text-sm lg:text-xl">Years Of Experice</div>
            </div>

            <div className="flex flex-col w-[40%] md:w-[22%] lg:w-[24%] justify-start items-start    py-6 rounded-[30px] mx-auto">
              <div className="px-5 mx-auto  text-6xl lg:text-8xl">
                {counterOn && (
                  <CountUp start={0} end={150} duration={2} delay={0} />
                )}
                +{" "}
              </div>
              <div className="px-5 mx-auto text-sm lg:text-xl">Total Project</div>
            </div>

            <div className="flex flex-col w-[40%] md:w-[22%] lg:w-[24%] justify-start items-start     py-6 rounded-[30px] mx-auto">
              <div className="px-5 mx-auto  text-6xl lg:text-8xl">
                {counterOn && (
                  <CountUp start={0} end={10} duration={2} delay={0} />
                )}
                +{" "}
              </div>
              <div className="px-5 mx-auto text-sm lg:text-xl">Industry Expertise</div>
            </div>

            <div className="flex flex-col w-[40%] md:w-[22%] lg:w-[24%] justify-start items-start    py-6 rounded-[30px] mx-auto">
              <div className="px-5 mx-auto  text-6xl lg:text-8xl">
                {counterOn && (
                  <CountUp start={0} end={50} duration={2} delay={0} />
                )}
                +{" "}
              </div>
              <div className="px-5 mx-auto text-sm lg:text-xl">Team Member</div>
            </div>
          </div>
        </ScrollTrigger>
      </div>
    </section>
  );
};

export default SectionWrapper(ProjectCount, "our-work");
