import React from "react";
import { SectionWrapper, MetaData } from "../../hoc";
import { fadeIn, textVariant } from "../../utils/motion";
import { motion, AnimatePresence } from "framer-motion";

import RowS from "./RowS";
import RRowS from "./RRowS";

const vp1 = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686820250/video1_cqyedl.png",
    des: "    In today's digital landscape, video production and photography play a vital role in captivating audiences, telling compelling stories, and building a strong brand presence. We understand the significance of visual content and offer top-notch video production and photography services to elevate your brand to new heights.",
  },
];
const vp2 = [
  {
    id: 1,
    img1: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686820962/video2_dxkr9z.png",
    des1: "We believe in the power of a single image to communicate volumes. Our experienced photographers are adapt at capturing the essence of your brand through stunning visuals. From product photography that highlights the unique features and benefits of your offerings to corporate headshots that exude professionalism and credibility, we excel in creating visually compelling images that align with your brand identity.",
  },
];
const vp3 = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686820964/video3_uvidzr.png",
    des: "   Our team understands that effective video production and photography go beyond technical skills.By understanding your brand values, target audience, and marketing objectives, we craft narratives that resonate with viewers on an emotional level, fostering a deeper connection and leaving a lasting impact.",
  },
];
const vp4 = [
  {
    id: 1,
    img1: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686820968/video4_vmwa1r.png",
    des1: " We are stay at the forefront of technological advancements in video production and photography. We utilize state-of-the-art equipment, cameras, and editing software to ensure the highest quality output.",
  },
];
const vp5 = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686820244/video5_j4wwvu.png",
    des: "    Our team works closely with you to understand your specific requirements, provide expert guidance, and ensure that the final output aligns with your vision. We are committed to delivering projects within agreed timelines without compromising on quality, ensuring a seamless experience for our clients.",
  },
];
const vp6 = [
  {
    id: 1,
    img1: "https://res.cloudinary.com/dhbu1kmvf/image/upload/v1686820258/video6_z0u2ro.png",
    des1: " Investing in digital marketing is investing in the future of your business. Let us be your trusted partner in navigating this dynamic landscape, delivering measurable growth and unlocking new opportunities for your brand.",
  },
];

const VPP = () => {
  return (
    <>
      <AnimatePresence>
        <motion.section
          initial={{ x: "-100%" }}
          animate={{ x: "0%" }}
          transition={{ duration: 1 }}
          exit={{ x: "100" }}
          className=" min-h-[100vh] pt-10 w-full relative top-0 left-0 bg-[#ee7b42]"
          key="videoproduction"
          id="videoproduction"
        >
          <MetaData title=" Video Production & Photography -- ASR Techno" />

          <div className="px-5  lg:px-28  pb-[70px] drop-shadow-sm">
            <motion.div variants={textVariant()}>
              <h2 className="text-white  md:text-8xl text-center  sm:text-[40px]  text-[30px] tracking-wider font-thin">
                Video Production & Photography
              </h2>
            </motion.div>

            <motion.p
              variants={fadeIn("", "", 0.5, 1)}
              className="mt-10   text-[18px] text-center  content "
            >
              In today's digital landscape, video production and photography
              play a vital role in captivating audiences, telling compelling
              stories, and building a strong brand presence. We understand the
              significance of visual content and offer top-notch video
              production and photography services to elevate your brand to new
              heights.
            </motion.p>

            <motion.div variants={textVariant()}>
              <h2 className="text-white  my-10 text-left  sm:text-[40px]  text-[30px] tracking-wider font-thin w-[95%] mx-auto">
                Professional Video Production
              </h2>
            </motion.div>

            {vp1 && vp1.map((rows1) => <RowS key={rows1.id} rows1={rows1} />)}

            <motion.div variants={textVariant()}>
              <h2 className="text-white  mt-10 text-right  sm:text-[40px]  text-[30px] tracking-wider font-thin mx-auto w-[95%]">
                High-Quality Photography
              </h2>
            </motion.div>

            {vp2 &&
              vp2.map((rrows1) => <RRowS key={rrows1.id} rrows1={rrows1} />)}

            <motion.div variants={textVariant()}>
              <h2 className="text-white  my-10 text-left  sm:text-[40px]  text-[30px] tracking-wider font-thin w-[95%] mx-auto">
                Creative Storytelling
              </h2>
            </motion.div>

            {vp3 && vp3.map((rows1) => <RowS key={rows1.id} rows1={rows1} />)}

            <motion.div variants={textVariant()}>
              <h2 className="text-white  mt-10 text-right  sm:text-[40px]  text-[30px] tracking-wider font-thin w-[95%] mx-auto">
                Cutting-Edge Equipment and Techniques
              </h2>
            </motion.div>
            {vp4 &&
              vp4.map((rrows1) => <RRowS key={rrows1.id} rrows1={rrows1} />)}

            <motion.div variants={textVariant()}>
              <h2 className="text-white  my-10 text-left  sm:text-[40px]  text-[30px] tracking-wider font-thin w-[95%] mx-auto">
                Seamless Collaboration and Timely Delivery
              </h2>
            </motion.div>

            {vp3 && vp3.map((rows1) => <RowS key={rows1.id} rows1={rows1} />)}

            <motion.div variants={textVariant()}>
              <h2 className="text-white  mt-10 text-right  sm:text-[40px]  text-[30px] tracking-wider font-thin w-[95%] mx-auto">
                BUSINESS THOUGHT
              </h2>
            </motion.div>

            {vp6 &&
              vp6.map((rrows1) => <RRowS key={rrows1.id} rrows1={rrows1} />)}
          </div>
        </motion.section>
      </AnimatePresence>
    </>
  );
};

export default SectionWrapper(VPP, "videoproduction");
